<template>
  <div class="favorites" v-if="user && Object.keys(user).length">
    <UserData />
    <div
      class="favorites__list-container"
      v-if="filteredHistory && filteredHistory.length"
    >
      <div class="favorites__title">История просмотров:</div>
      <div class="favorites__descr">
        История просмотров показывается только за последние 30 дней
      </div>
      <div class="favorites__list-grid">
        <div
          v-for="i in filteredHistoryLength"
          :key="filteredHistory[i - 1].object.id"
        >
          <Material
            v-if="
              filteredHistory[i - 1].object.content_type === 'video_material'
            "
            :item="formattedHistory[i - 1].object"
            :page="'VideoDetail'"
            @watchLater="
              watchLater(
                filteredHistory[i - 1].watch_later ? 'DELETE' : 'POST',
                filteredHistory[i - 1].object.slug,
                'video_material'
              )
            "
            @favorite="
              favorite(
                filteredHistory[i - 1].favorite ? 'DELETE' : 'POST',
                filteredHistory[i - 1].object.slug,
                'video_material'
              )
            "
            @clickMaterials="clickText"
          />
          <Material
            v-if="filteredHistory[i - 1].object.content_type === 'podcast'"
            :item="formattedHistory[i - 1].object"
            :page="'PodcastDetail'"
            @watchLater="
              watchLater(
                filteredHistory[i - 1].watch_later ? 'DELETE' : 'POST',
                filteredHistory[i - 1].object.slug,
                'podcast'
              )
            "
            @favorite="
              favorite(
                filteredHistory[i - 1].favorite ? 'DELETE' : 'POST',
                filteredHistory[i - 1].object.slug,
                'podcast'
              )
            "
            @clickMaterials="clickText"
          />
          <Material
            v-if="
              filteredHistory[i - 1].object.content_type === 'text_material'
            "
            :item="formattedHistory[i - 1].object"
            :page="'TextDetail'"
            @watchLater="
              watchLater(
                filteredHistory[i - 1].watch_later ? 'DELETE' : 'POST',
                filteredHistory[i - 1].object.slug,
                'text_material'
              )
            "
            @favorite="
              favorite(
                filteredHistory[i - 1].favorite ? 'DELETE' : 'POST',
                filteredHistory[i - 1].object.slug,
                'text_material'
              )
            "
            @clickMaterials="clickText"
          />
          <Medication
            v-if="filteredHistory[i - 1].object.content_type === 'drug'"
            :item="formattedHistory[i - 1].object"
            :page="'Medications'"
            @watchLater="
              watchLater(
                filteredHistory[i - 1].watch_later ? 'DELETE' : 'POST',
                filteredHistory[i - 1].object.slug,
                'drug'
              )
            "
            @favorite="
              favorite(
                filteredHistory[i - 1].favorite ? 'DELETE' : 'POST',
                filteredHistory[i - 1].object.slug,
                'drug'
              )
            "
            @toMediDetail="clickText"
          />
        </div>
      </div>
      <div class="favorites__buttons">
        <div
          class="favorites__load button button_pink"
          v-if="filteredHistory.length > listLength"
          @click="loadMore()"
        >
          Загрузить ещё
          <span class="ml-2">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.5 12.7501L4.5 12.0001H3V12.7501H4.5ZM19.5 12.7501C19.5 16.8923 16.1421 20.2501 12 20.2501L12 21.7501C16.9706 21.7501 21 17.7207 21 12.7501L19.5 12.7501ZM12 20.2501C7.85786 20.2501 4.5 16.8923 4.5 12.7501H3C3 17.7207 7.02944 21.7501 12 21.7501L12 20.2501ZM12 5.25012C16.1421 5.25012 19.5 8.60799 19.5 12.7501L21 12.7501C21 7.77956 16.9706 3.75012 12 3.75012V5.25012ZM7.56864 6.69856C8.8101 5.78779 10.3412 5.25012 12 5.25012V3.75012C10.0114 3.75012 8.17143 4.39596 6.68136 5.48912L7.56864 6.69856Z"
                fill="#B36697"
              />
              <path
                d="M8.08391 1.05776L6.37094 6.61929L11.9325 8.33225"
                stroke="#B36697"
                stroke-width="1.5"
              />
            </svg>
          </span>
        </div>
        <div class="favorites__up button button_empty-pink" @click="toTop()">
          К началу страницы
          <span class="ml-2">
            <svg
              width="18"
              height="10"
              viewBox="0 0 18 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.25 8.75012L8.75 1.25012L16.25 8.75012"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>

    <div class="favorites__list-container" v-else>
      <div class="favorites__title">Здесь пока пусто</div>
      <div class="favorites__descr">
        Чтобы увидеть Историю просмотров - посмотрите материалы в Библиотеке
      </div>
      <router-link
        :to="{ name: 'Library' }"
        class="favorites__btn button button_pink mt-6"
      >
        Перейти в библиотеку
      </router-link>
    </div>
  </div>
</template>

<script>
import UserData from "../../components/pageComponents/cabinet/UserData.vue";
import Material from "../../components/pageComponents/Material.vue";
import Medication from "../../components/pageComponents/Medication.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: { UserData, Material, Medication },
  name: "History",
  data: () => ({
    count: 6,
    listLength: 6,
  }),
  computed: {
    ...mapGetters(["history", "user"]),
    filteredHistoryLength() {
      return this.listLength > this.filteredHistory.length
        ? this.filteredHistory.length
        : this.listLength;
    },
    filteredHistory() {
      return this.history.filter((el) => el.object);
    },
    formattedHistory() {
      return this.filteredHistory.map(function (el) {
        return {
          ...el,
          object: {
            ...el.object,
            watch_later: el.watch_later,
            favorite: el.favorite,
          },
        };
      });
    },
  },
  methods: {
    ...mapActions([
      "getHistory",
      "fetchVideoFavorite",
      "fetchPodcastFavorite",
      "fetchTextFavorite",
      "fetchMedicationFavorite",
      "fetchVideoWatchLater",
      "fetchPodcastWatchLater",
      "fetchTextWatchLater",
      "fetchMedicationWatchLater",
    ]),
    ...mapMutations(["updateRecommendations"]),
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    loadMore() {
      this.listLength =
        this.listLength + this.count > this.filteredHistory.length
          ? this.filteredHistory.length
          : this.listLength + this.count;
    },
    async watchLater(method, slug, type) {
      switch (type) {
        case "video_material":
          await this.fetchVideoWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "podcast":
          await this.fetchPodcastWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "text_material":
          await this.fetchTextWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "drug":
          await this.fetchMedicationWatchLater({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
      }
      await this.getHistory(this.user.id);
    },
    async favorite(method, slug, type) {
      switch (type) {
        case "video_material":
          await this.fetchVideoFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "podcast":
          await this.fetchPodcastFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "text_material":
          await this.fetchTextFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
        case "drug":
          await this.fetchMedicationFavorite({
            method: method,
            slug: slug,
            favoritePage: true,
          });
          break;
      }
      await this.getHistory(this.user.id);
    },
    clickText(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "LK recent views click", {
          "LK recent views click": {
            "material name": name,
            ...this.$root.ymFields,
          },
        });
      }
    },
  },
  async mounted() {
    this.updateRecommendations({});
    if (this.user && Object.keys(this.user).length) {
      await this.getHistory(this.user.id);
    }
  },
  watch: {
    async user() {
      await this.getHistory(this.user.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.favorites {
  background-color: #f8f8f8;
  padding-left: 32px;
  padding-top: 48px;
  padding-bottom: 80px;
  z-index: 1000;
  position: relative;
  @media screen and (max-width: 1220px) {
    padding-left: 0;
  }

  &__title {
    margin-bottom: 24px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
  }
  &__descr {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  &__list-grid {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(3, 280px);
    column-gap: 32px;
    row-gap: 32px;

    @media screen and (max-width: 1220px) {
      grid-template-columns: repeat(2, calc(50% - 16px));
      row-gap: 24px;
    }

    @media screen and (max-width: 767px) {
      grid-template-columns: repeat(1, 100%);
      row-gap: 24px;
      column-gap: 0;
    }
  }
  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1220px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__load {
    width: 175px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__up {
    margin-left: auto;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }

  &__btn {
    max-width: fit-content;
    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: none;
    }
  }
}

.medication-item {
  height: 100%;
}

.material {
  height: 100%;
}
</style>
